/**
 * DEPRECATED: 2023/10 以降は embedded.tsx を提供しているが、後方互換性のため当面このファイルも残しておくこと
 * 何らかの手段でユーザーがこのスクリプトをWebサイトに埋め込んでいないことが確認できたら消しても良い
 */

import EmbeddedFloatingScenarioPlayer from '../components/EmbeddedFloatingScenarioPlayer'
import {
  DesktopDisplayHorizontalOffsetType,
  DesktopDisplayType,
  DesktopDisplayVerticalOffsetType,
  MobileDisplayType,
} from '../orval/loovPublic'
import embeddedRender from './utils/embeddedRender'

declare global {
  // eslint-disable-next-line no-var
  var LOOV_P: unknown
  // eslint-disable-next-line no-var
  var LOOV_M: unknown
}

;(() => {
  /**
   * Execution
   */
  main()

  /**
   * Functions
   */
  function main() {
    embeddedRender(
      <EmbeddedFloatingScenarioPlayer
        scenarioId={getGlobalScenarioId()}
        desktopDisplayType={legacyGetGlobalDesktopDisplayType()}
        desktopDisplayVerticalOffset={50}
        desktopDisplayVerticalOffsetType={DesktopDisplayVerticalOffsetType.FROM_BOTTOM}
        desktopDisplayHorizontalOffset={50}
        desktopDisplayHorizontalOffsetType={DesktopDisplayHorizontalOffsetType.FROM_RIGHT}
        mobileDisplayType={legacyGetGlobalMobileDisplayType()}
        scenarioMobileThumbnailId={null}
      />,
      { parent: document.body },
    )
  }

  function getGlobalScenarioId(): string {
    // 必須
    if (typeof window.LOOV_SID !== 'string') {
      throw Error('LOOVの埋め込みスクリプトが不正です。再設定してください。')
    }

    return window.LOOV_SID
  }

  /**
   * 旧埋め込みタグでは LOOV_P = "left" | "right" で表示位置を制御していた
   */
  function legacyGetGlobalDesktopDisplayType(): DesktopDisplayType {
    // 変数未定義対策
    if (typeof window.LOOV_P === 'undefined') {
      window.LOOV_P = undefined
    }

    if (window.LOOV_P === 'left') {
      return DesktopDisplayType.BOTTOM_LEFT
    }

    if (window.LOOV_P === 'right') {
      return DesktopDisplayType.BOTTOM_RIGHT
    }

    // デフォルト値
    return DesktopDisplayType.BOTTOM_RIGHT
  }

  /**
   * 旧埋め込みタグでは LOOV_M = true と LOOV_P の組み合わせで表示有無を制御していた
   */
  function legacyGetGlobalMobileDisplayType(): MobileDisplayType {
    // 変数未定義対策
    if (typeof window.LOOV_M === 'undefined') {
      window.LOOV_M = undefined
    }
    if (typeof window.LOOV_P === 'undefined') {
      window.LOOV_P = undefined
    }

    if (window.LOOV_M === true) {
      if (window.LOOV_P === 'left') {
        return MobileDisplayType.BOTTOM_LEFT
      }

      return MobileDisplayType.BOTTOM_RIGHT
    }

    // デフォルト値
    return MobileDisplayType.NONE
  }
})()
